const MESSAGE = {
  EN: {
    camera_detail_header: 'Camera Details',
    violation_case_header: 'Violation Cases',
    sort_by: 'Sort by',
    time_new_option: 'Newest',
    time_old_option: 'Oldest',
    violation_type_option: 'Violation Type',
    no_violation_case: 'No Violation Case',
    select_sort_by: 'Select Sort By',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again',
    report_dialog_title: 'Report case',
    report_dialog_message: 'Are you sure you want to report this case? You can unreport at anytime',
    report_dialog_report_btn: 'Report',
    report_dialog_cancel_btn: 'Cancel',
    delete_dialog_title: 'Delete case',
    delete_dialog_message: 'Are you sure you want to delete this case?',
    delete_dialog_delete_btn: 'Delete',
    delete_dialog_cancel_btn: 'Cancel',
    toast_title: 'Successful',
    toast_report_message: 'Case has been reported',
    toast_unreport_message: 'Case has been unreported',
    toast_delete_message: 'Case has been deleted',
    all_cases_filter: 'All cases',
    report_cases_filter: 'Reported cases',
    report_fail_modal_title: "Can't report this case",
    unreport_fail_modal_title: "Can't unreport this case",
    report_fail_modal_message: 'This case might have been already deleted. Please try again',
    dup_report_modal_message: 'This case might have been already reported. Please try again',
    dup_unreport_modal_message: 'This case might have been already unreported. Please try again',
    delete_fail_modal_title: "Can't delete this case",
    okay_button: 'OK'
  },
  TH: {
    camera_detail_header: 'รายละเอียดกล้อง',
    violation_case_header: 'กรณีการฝ่าฝืน',
    sort_by: 'จัดเรียงตาม',
    time_new_option: 'ใหม่สุด',
    time_old_option: 'เก่าสุด',
    violation_type_option: 'ประเภทการฝ่าฝืน',
    no_violation_case: 'ไม่พบการฝ่าฝืน',
    select_sort_by: 'เลือกจัดเรียงตาม',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง',
    report_dialog_title: 'รายงานการฝ่าฝืน',
    report_dialog_message: 'คุณต้องการรายงานการฝ่าฝืนนี้หรือไม่ คุณสามารถยกเลิกรายงานได้ทุกเวลา',
    report_dialog_report_btn: 'รายงาน',
    report_dialog_cancel_btn: 'ยกเลิก',
    delete_dialog_title: 'ลบการฝ่าฝืน',
    delete_dialog_message: 'คุณต้องการลบการฝ่าฝืนนี้หรือไม่?',
    delete_dialog_delete_btn: 'ลบ',
    delete_dialog_cancel_btn: 'ยกเลิก',
    toast_title: 'สำเร็จ',
    toast_report_message: 'การฝ่าฝืนนี้ถูกรายงานแล้ว',
    toast_unreport_message: 'การฝ่าฝืนนี้ถูกยกเลิกรายงานแล้ว',
    toast_delete_message: 'การฝ่าฝืนนี้ถูกลบแล้ว',
    all_cases_filter: 'กรณีทั้งหมด',
    report_cases_filter: 'กรณีที่ถูกรายงาน',
    report_fail_modal_title: 'ไม่สามารถรายงานได้',
    report_fail_modal_message: 'การฝ่าฝืนนี้อาจถูกลบแล้ว กรุณาลองใหม่',
    unreport_fail_modal_title: 'ไม่สามารถยกเลิกรายงานได้',
    dup_report_modal_message: 'การฝ่าฝืนนี้อาจถูกรายงานไปแล้ว กรุณาลองใหม่',
    dup_unreport_modal_message: 'การฝ่าฝืนนี้อาจถูกยกเลิกรายงานไปแล้ว กรุณาลองใหม่',
    okay_button: 'ตกลง',
    delete_fail_modal_title: 'ไม่สามารถลบการฝ่าฝืนนี้ได้'
  }
}

export default MESSAGE
