import styled from 'styled-components'

export default styled.div`
  .violation-case-wrapper {
    display: flex;
    flex-wrap: wrap;
    .case-card {
      margin-bottom: 30px;
      &:nth-child(odd) {
        margin-right: 20px;
      }
    }
  }
  .no-violation-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12%;
    img {
      width: 50px;
      height: 37px;
      margin-bottom: 30px;
    }
    div {
      opacity: 0.5;
      font-family: 'Prompt', sans-serif;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
  .date-time-container {
    display: flex;
    align-items: center;
    .date-picker-container {
      margin-right: 20px;
    }
  }
  .pagination-container {
    display: flex;
    justify-content: flex-end;
    .pagination-wrapper {
      display: flex;
      li {
        list-style: none;
        font-family: 'Prompt', sans-serif;
        font-size: 14px;
        font-weight: bold;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
        a:focus {
          outline: none;
        }
      }
      .active-page {
        min-width: 30px;
        height: 30px;
        padding: 3px;
        border-radius: 5px;
        background: ${(props) => props.theme.darkBackground};
        font-family: 'Prompt', sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          cursor: default;
        }
      }
      .disabled {
        opacity: 0.2;
        &:hover {
          cursor: default;
        }
      }
    }
  }
`
