/* eslint-disable no-undef */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EachViolationDetailStyled from './styledComponent'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import ListBoardWrapper from '../../components/ListBoardWrapper/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import DashboardTop from '../../components/DashboardTop/Loadable'
import DatePickerComponent from '../../components/DatePickerComponent/Loadable'
import DateFilterComponent from '../../components/DateFilterComponent/Loadable'
import Dropdown from '../../components/Dropdown/Loadable'
import CameraDetailCard from '../../components/CameraDetailCard/Loadable'
import ViolationCaseCard from '../../components/ViolationCaseCard/Loadable'
import ToastComponent from '../../components/ToastComponent/Loadable'
import NoCaseIcon from '../../asset/images/emptyCase.svg'
import NextIcon from '../../asset/images/button/next-icon.svg'
import PrevIcon from '../../asset/images/button/prev-icon.svg'

import * as EachViolationActions from './actions'
import * as DashboardActions from '../DashboardPage/actions'
import * as ViolationDetailActions from '../ViolationDetailPage/actions'
import * as ApplicationActions from '../ApplicationLayout/actions'
import * as NotificationActions from '../NotificationSidebar/actions'

import fetchErrorHandler from '../../utils/fetchErrorHandler'
import MESSAGE from './message'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE, FRONTEND_ROUTES, THAI_LANGUAGE } from '../../utils'

const LIST_COLOR_BARS = [
  {
    key: 'Normal Activity',
    color: '#4a90e2'
  },
  {
    key: 'Violation',
    color: '#f16745'
  }
]

let timing

export class EachViolationDetailPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 0,
      filterViolationCase: 'desc',
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      },
      reportCaseModalData: {
        show: false,
        title: '',
        message: '',
        onOverLayClose: undefined,
        onSecondaryButtonClick: undefined,
        onPrimaryButtonClick: undefined,
        secondaryButtonText: '',
        primaryButtonText: ''
      },
      showCase: 'all'
    }
    this.abortController = new AbortController()
  }
  componentDidMount() {
    this.loadCameraData(this.props.match.params.id, this.props.match.params.siteId, this.state.currentPage + 1)
    this.initialIntervalLoadData()
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleRouterChanged(prevProps)
    this._handleFetchLoadCameraData(prevProps)
    this._handleFetchSyncCameraData(prevProps)
    this._handleFetchReportViolation(prevProps)
    this._handleFetchDeleteViolation(prevProps)
  }

  componentWillUnmount() {
    clearInterval(timing)
  }

  initialIntervalLoadData() {
    timing = setInterval(() => {
      const { dateFilterType } = this.props
      const { filterViolationCase } = this.state
      const day = this.getDayRequest()
      const reported = this.state.showCase === 'report' ? true : undefined
      this._syncCameraList()
      this.props.syncEachViolation(
        this.props.match.params.id,
        this.props.match.params.siteId,
        day,
        dateFilterType,
        filterViolationCase.value,
        this.state.currentPage + 1,
        reported
      )
    }, process.env.INTERVAL_CALL_API_MINUTES * 60 * 1000)
  }

  async handleRouterChanged(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      await this.setState({
        currentPage: 0
      })
      this.loadCameraData(this.props.match.params.id, this.props.match.params.siteId, this.state.currentPage + 1)
    }
  }

  _handleFetchLoadCameraData(prevProps) {
    if (prevProps.loadCameraDataStatus === FETCH_STATUS_REQUEST && this.props.loadCameraDataStatus === FETCH_STATUS_FAILURE) {
      this.handleLoadingFailure()
    }
  }

  _handleFetchSyncCameraData(prevProps) {
    if (prevProps.syncCameraDataStatus === FETCH_STATUS_REQUEST && this.props.syncCameraDataStatus === FETCH_STATUS_FAILURE) {
      this.handleSyncFailure()
    }
  }

  _handleFetchDeleteViolation(prevProps) {
    if (prevProps.deleteViolationStatus === FETCH_STATUS_REQUEST && this.props.deleteViolationStatus === FETCH_STATUS_SUCCESS) {
      this._handleNotifyToastDeleteSuccess()
    } else if (prevProps.deleteViolationStatus === FETCH_STATUS_REQUEST && this.props.deleteViolationStatus === FETCH_STATUS_FAILURE) {
      this._handleDeleteFail()
    }
  }

  _handleFetchReportViolation(prevProps) {
    if (prevProps.reportViolationStatus === FETCH_STATUS_REQUEST && this.props.reportViolationStatus === FETCH_STATUS_SUCCESS) {
      this._handleNotifyToastReportSuccess(prevProps)
      this._handleSendSupportMail(prevProps)
    } else if (prevProps.reportViolationStatus === FETCH_STATUS_REQUEST && this.props.reportViolationStatus === FETCH_STATUS_FAILURE) {
      this._handleReportViolationError()
    }
  }

  _handleDeleteFail = () => {
    const statusCode = this.props.deleteViolationError.status
    const title = this.getMessage('delete_fail_modal_title')
    const message = this.getMessage('report_fail_modal_message')
    fetchErrorHandler(
      statusCode,
      {
        404: () => this._setShowReportViolationFailedModal(title, message)
      },
      this.showDefaultFailureModal
    )
  }

  _handleReportViolationError = () => {
    const statusCode = this.props.reportViolationError.status
    let title = ''
    let message = ''
    if (statusCode === 400) {
      title = this.props.isReportViolation ? this.getMessage('report_fail_modal_title') : this.getMessage('unreport_fail_modal_title')
      message = this.props.isReportViolation ? this.getMessage('dup_report_modal_message') : this.getMessage('dup_unreport_modal_message')
    } else if (statusCode === 404) {
      title = this.props.isReportViolation ? this.getMessage('report_fail_modal_title') : this.getMessage('unreport_fail_modal_title')
      message = this.getMessage('report_fail_modal_message')
    }
    fetchErrorHandler(
      statusCode,
      {
        400: () => this._setShowReportViolationFailedModal(title, message),
        404: () => this._setShowReportViolationFailedModal(title, message)
      },
      this.showDefaultFailureModal
    )
  }

  _setShowReportViolationFailedModal = (title, message) => {
    const okayText = this.getMessage('okay_button')
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onOverLayClose: undefined,
        onPrimaryButtonClick: this.setHideFailureModalData,
        primaryButtonText: okayText
      }
    })
  }

  _handleNotifyToastDeleteSuccess = () => {
    const title = this.getMessage('toast_title')
    const message = this.getMessage('toast_delete_message')
    this._notifyToast(title, message)
  }

  _handleNotifyToastReportSuccess = (prevProps) => {
    const prevViolation = prevProps.listCameraViolation.find((violation) => violation.id === prevProps.toggledReportViolationId)
    const violation = this.props.listCameraViolation.find((violation) => violation.id === this.props.toggledReportViolationId)
    const title = this.getMessage('toast_title')
    const message =
      !prevViolation.reported && violation.reported ? this.getMessage('toast_report_message') : this.getMessage('toast_unreport_message')
    if (violation) {
      this._notifyToast(title, message)
    }
  }

  _handleSendSupportMail = (prevProps) => {
    const prevViolation = prevProps.listCameraViolation.find((violation) => violation.id === prevProps.toggledReportViolationId)
    const violation = this.props.listCameraViolation.find((violation) => violation.id === this.props.toggledReportViolationId)
    if (!prevViolation.reported && violation.reported) {
      this._sendSupportMail(violation)
    }
  }

  _notifyToast = (title, message) => {
    toast.success(
      <div className="toast-text">
        <p className="title">{title}</p>
        <p className="message">{message}</p>
      </div>
    )
  }

  handleLoadingFailure() {
    const statusCode = this.props.loadCameraDataError.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  handleSyncFailure() {
    const statusCode = this.props.syncCameraDataError.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  forceLogin() {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  _syncCameraList() {
    const siteId = this.props.match.params.siteId
    this.props.syncCameraList(siteId)
    this.props.getListNotification(siteId)
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }
  getDayRequest() {
    let day = this.props.date.format('YYYY-MM-DD')
    if (this.props.dateFilterType === 'week') {
      day = moment(this.props.date).startOf('week').format('YYYY-MM-DD')
    }
    return day
  }

  loadCameraData(cameraId, siteId, pageNumber) {
    const { dateFilterType } = this.props
    const { filterViolationCase } = this.state
    const reported = this.state.showCase === 'report' ? true : undefined
    const day = this.getDayRequest()
    this.abortController.abort()
    this.abortController = new AbortController()
    this.props.loadEachViolation(cameraId, siteId, day, dateFilterType, filterViolationCase.value, pageNumber, this.abortController.signal, reported)
  }

  async handleFilterChange(type) {
    await this.props.updateDateFilter(type)
    await this.setState({
      currentPage: 0
    })
    this.loadCameraData(this.props.match.params.id, this.props.match.params.siteId, this.state.currentPage + 1)
  }

  async handleDateChange(date) {
    await this.props.updateDatePicker(date)
    await this.setState({
      currentPage: 0
    })
    this.loadCameraData(this.props.match.params.id, this.props.match.params.siteId, this.state.currentPage + 1)
  }

  async handleViolationCaseFilterChange(data) {
    await this.setState({
      currentPage: 0,
      filterViolationCase: data
    })
    if (data.value !== '') {
      this.loadCameraData(this.props.match.params.id, this.props.match.params.siteId, this.state.currentPage + 1)
    }
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language || THAI_LANGUAGE]
    return msg[property]
  }

  getDateComponent() {
    return (
      <div className="date-time-container">
        <div className="date-picker-container">
          <DatePickerComponent value={this.props.date} onDateChange={(date) => this.handleDateChange(date)} type={this.props.dateFilterType} />
        </div>
        <div className="date-filter-container">
          <DateFilterComponent
            value={this.props.dateFilterType}
            onFilterChanged={(value) => this.handleFilterChange(value)}
            language={this.props.currentUser?.language || THAI_LANGUAGE}
          />
        </div>
      </div>
    )
  }

  getListGraphDetail(arrayData) {
    if (arrayData.length === 0) {
      return []
    }
    const sampleData = arrayData.reduce((prev, current) => {
      return Object.assign({}, prev, current)
    }, {})
    delete sampleData['label']
    const listGraphDetail = Object.keys(sampleData).map((key) => {
      const colorData = LIST_COLOR_BARS.find((data) => data.key === key)
      return {
        name: key,
        color: colorData?.color || '#f5a623'
      }
    })
    return listGraphDetail
  }

  getCameraViolationTopZone() {
    let output = <CameraDetailCard data={{}} isLoading={true} />
    if (this.props.loadCameraDataStatus !== FETCH_STATUS_REQUEST && this.props.cameraData) {
      output = (
        <CameraDetailCard
          data={this.props.cameraData}
          language={this.props.currentUser?.language || THAI_LANGUAGE}
          listBars={this.getListGraphDetail(this.props.cameraData.activity_graph)}
          oneArea={this.props.areaCount === 1}
        />
      )
    }
    return output
  }

  setShowCase = async (value) => {
    await this.setState({
      showCase: value,
      currentPage: 0
    })
    this.loadCameraData(this.props.match.params.id, this.props.match.params.siteId, this.state.currentPage + 1)
  }

  getFilterViolations() {
    const violationCasesOptions = [
      { text: `-- ${this.getMessage('select_sort_by')} --`, value: '' },
      { text: this.getMessage('time_new_option'), value: 'desc' },
      { text: this.getMessage('time_old_option'), value: 'asc' },
      { text: this.getMessage('violation_type_option'), value: 'violation_type' }
    ]

    const allCaseText = this.getMessage('all_cases_filter')
    const reportCaseText = this.getMessage('report_cases_filter')
    const allCaseBtnClassName = this.state.showCase === 'all' ? 'active' : ''
    const reportCaseBtnClassName = this.state.showCase === 'report' ? 'active' : ''
    return (
      <>
        <div className="filter-btn-wrapper">
          <button className={`filter-button ${allCaseBtnClassName}`} value="all" onClick={() => this.setShowCase('all')}>
            {allCaseText}
          </button>
          <div className="vertical-line" />
          <button
            className={`filter-button ${reportCaseBtnClassName}`}
            ref={(btn) => (this.reportCaseBtnRef = btn)}
            value="report"
            onClick={() => this.setShowCase('report')}
          >
            {reportCaseText}
          </button>
        </div>
        <Dropdown
          options={violationCasesOptions}
          label={this.getMessage('sort_by')}
          onChange={(data) => this.handleViolationCaseFilterChange(data)}
          value={this.state.filterViolationCase}
          labelPosition="left"
          buttonColor="#27a448"
          language={this.props.currentUser?.language || THAI_LANGUAGE}
        />
      </>
    )
  }

  async handlePageChange(page) {
    await this.setState({
      currentPage: page.selected
    })
    this.loadCameraData(this.props.match.params.id, this.props.match.params.siteId, this.state.currentPage + 1)
  }

  _shouldRenderPagination = () => {
    const hasViolation = this.props.listCameraViolation.length !== 0
    const idleLoad = this.props.loadCameraDataStatus === FETCH_STATUS_IDLE
    return hasViolation && idleLoad
  }

  renderPagination() {
    if (this._shouldRenderPagination()) {
      return (
        <ReactPaginate
          previousLabel={<img src={PrevIcon} alt="" />}
          nextLabel={<img src={NextIcon} alt="" />}
          breakLabel={'...'}
          breakClassName={''}
          pageCount={this.props.violationCaseAllPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          onPageChange={(data) => this.handlePageChange(data)}
          containerClassName={'pagination-wrapper'}
          activeClassName={'active-page'}
          forcePage={this.state.currentPage}
        />
      )
    }
    return null
  }

  getNoViolationCase() {
    return (
      <div className="no-violation-wrapper">
        <img src={NoCaseIcon} alt="" />
        <div>{this.getMessage('no_violation_case')}</div>
      </div>
    )
  }

  setShowModalData = (title, message, onOverLayClose, onPrimaryButtonClick, onSecondaryButtonClick, primaryButtonText, secondaryButtonText) => {
    this.setState({
      reportCaseModalData: {
        show: true,
        title,
        message,
        onOverLayClose,
        onPrimaryButtonClick,
        onSecondaryButtonClick,
        primaryButtonText,
        secondaryButtonText
      }
    })
  }

  setHideModalData = () => {
    this.setState({
      reportCaseModalData: {
        show: false,
        title: '',
        message: '',
        onOverLayClose: undefined,
        onPrimaryButtonClick: undefined,
        onSecondaryButtonClick: undefined,
        primaryButtonText: '',
        secondaryButtonText: ''
      }
    })
  }

  _sendSupportMail = (violationData) => {
    const date = new Date()
    window.open(
      `mailto:farmlab_support@sertiscorp.com?subject=Farmlab - (${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${
        date.getUTCHours() + 7
      }:${date.getUTCMinutes()}) Farm: ${this.props.siteDetailData?.name} Camera id: ${this.props.cameraData?.camera?.camera_id}
      &body=Violation Data:%0D%0A
      date-time: ${violationData.violation_date}, ${violationData.violation_time}%0D%0A
      image: ${violationData.frame_url}
      `,
      '_blank'
    )
  }

  _confirmReportViolation = async (violationId, reported, violationData) => {
    await this.props.reportViolation(violationId, reported)
    this.setHideModalData()
    this.loadCameraData(this.props.match.params.id, this.props.match.params.siteId, this.state.currentPage + 1)
  }

  async _confirmDeleteViolation(violationId) {
    await this.props.deleteViolation(violationId)
    this.setHideModalData()
    this.loadCameraData(this.props.match.params.id, this.props.match.params.siteId, this.state.currentPage + 1)
  }

  deleteViolationById(violationId) {
    const title = this.getMessage('delete_dialog_title')
    const message = this.getMessage('delete_dialog_message')
    const primaryButtonText = this.getMessage('delete_dialog_delete_btn')
    const secondaryButtonText = this.getMessage('delete_dialog_cancel_btn')
    this.setShowModalData(
      title,
      message,
      this.setHideModalData,
      () => this._confirmDeleteViolation(violationId),
      this.setHideModalData,
      primaryButtonText,
      secondaryButtonText
    )
  }

  reportViolationById(violationId, reported, violationData) {
    if (reported === true) {
      const title = this.getMessage('report_dialog_title')
      const message = this.getMessage('report_dialog_message')
      const primaryButtonText = this.getMessage('report_dialog_report_btn')
      const secondaryButtonText = this.getMessage('report_dialog_cancel_btn')
      this.setShowModalData(
        title,
        message,
        this.setHideModalData,
        () => this._confirmReportViolation(violationId, reported, violationData),
        this.setHideModalData,
        primaryButtonText,
        secondaryButtonText
      )
    } else {
      this._confirmReportViolation(violationId, reported, violationData)
    }
  }

  renderViolationCaseCard(violations) {
    let output = violations.map((data, id) => {
      return (
        <ViolationCaseCard
          key={data.violation_type + id}
          data={data}
          currentUser={this.props.currentUser}
          deleteViolationClick={() => this.deleteViolationById(data.id)}
          reportViolationById={(violationId, reported, violationData) => this.reportViolationById(violationId, reported, violationData)}
        />
      )
    })

    if (this.props.loadCameraDataStatus === FETCH_STATUS_REQUEST) {
      output = <ViolationCaseCard data={{}} isLoading={true} />
    }

    return <div className="violation-case-wrapper">{output}</div>
  }

  getContent() {
    let output = <ViolationCaseCard data={{}} isLoading={true} />
    if (this.props.loadCameraDataStatus !== FETCH_STATUS_REQUEST && this.props.listCameraViolation.length > 0) {
      output = this.renderViolationCaseCard(this.props.listCameraViolation)
    } else if (this.props.listCameraViolation.length === 0 && this.props.loadCameraDataStatus === FETCH_STATUS_IDLE) {
      output = this.getNoViolationCase()
    }
    return output
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }
  getReportModal() {
    let output = null
    if (this.state.reportCaseModalData.show) {
      output = (
        <ModalNotification
          className="confirm-report-modal"
          title={this.state.reportCaseModalData.title}
          message={this.state.reportCaseModalData.message}
          onOverLayClose={this.state.reportCaseModalData.onOverLayClose}
          onPrimaryButtonClick={this.state.reportCaseModalData.onPrimaryButtonClick}
          onSecondaryButtonClick={this.state.reportCaseModalData.onSecondaryButtonClick}
          primaryButtonText={this.state.reportCaseModalData.primaryButtonText}
          secondaryButtonText={this.state.reportCaseModalData.secondaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <EachViolationDetailStyled>
        <ListBoardWrapper title={this.getMessage('camera_detail_header')} filter={this.getDateComponent()}>
          <DashboardTop alignMent="horizontal">{this.getCameraViolationTopZone()}</DashboardTop>
        </ListBoardWrapper>
        <ListBoardWrapper title={this.getMessage('violation_case_header')} filter={this.getFilterViolations()}>
          {this.getContent()}
          <div className="pagination-container">{this.renderPagination()}</div>
        </ListBoardWrapper>
        {this.getFailureModal()}
        {this.getReportModal()}
        <ToastComponent />
      </EachViolationDetailStyled>
    )
  }
}

/*istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    cameraData: state.eachViolationDetailPage.cameraDetail,
    listCameraViolation: state.eachViolationDetailPage.cameraViolation,
    loadCameraDataStatus: state.eachViolationDetailPage.loadCameraData.fetchStatus,
    loadCameraDataError: state.eachViolationDetailPage.loadCameraData.error,
    violationCaseAllPage: state.eachViolationDetailPage.allPage,
    syncCameraDataStatus: state.eachViolationDetailPage.loadCameraData.syncStatus,
    syncCameraDataError: state.eachViolationDetailPage.loadCameraData.error,
    filterViolationCase: state.eachViolationDetailPage.filterViolationCase,
    reportViolationStatus: state.eachViolationDetailPage.reportViolation.fetchStatus,
    isReportViolation: state.eachViolationDetailPage.reportViolation.reportAction,
    toggledReportViolationId: state.eachViolationDetailPage.reportViolation.violationId,
    deleteViolationStatus: state.eachViolationDetailPage.deleteViolation.fetchStatus,
    reportViolationError: state.eachViolationDetailPage.reportViolation.error,
    deleteViolationError: state.eachViolationDetailPage.deleteViolation.error,
    areaCount: state.eachViolationDetailPage.areaCount,
    date: state.applicationPage.date,
    dateFilterType: state.applicationPage.dateFilterType,
    currentUser: state.loginPage.loginAPI.userData,
    siteDetailData: state.eachViolationDetailPage.siteDetail
  }
}

/*istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    Object.assign({}, ApplicationActions, EachViolationActions, DashboardActions, ViolationDetailActions, NotificationActions),
    dispatch
  )
}

EachViolationDetailPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  violationCaseAllPage: PropTypes.number,
  cameraData: PropTypes.shape({
    cameraDetail: PropTypes.shape({
      activity_graph: PropTypes.arrayOf(
        PropTypes.shape({
          'Number of People': PropTypes.string,
          Violation: PropTypes.string,
          label: PropTypes.string
        })
      ),
      area_name: PropTypes.string,
      camera: PropTypes.shape({
        area_id: PropTypes.number,
        camera_id: PropTypes.number,
        camera_status: PropTypes.string,
        gps: PropTypes.object,
        health_check_time: PropTypes.string,
        image: PropTypes.string,
        is_active: PropTypes.bool,
        name: PropTypes.string,
        resolution_height: PropTypes.number,
        resolution_width: PropTypes.number,
        site_id: PropTypes.number,
        zones: PropTypes.array
      }),
      violation_count: PropTypes.number
    })
  }),
  listCameraViolation: PropTypes.arrayOf(PropTypes.object),
  loadCameraDataStatus: PropTypes.string,
  loadEachViolation: PropTypes.func,
  syncCameraDataStatus: PropTypes.string,
  syncEachViolation: PropTypes.func,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    language: PropTypes.string
  }),
  reportViolationStatus: PropTypes.string,
  isReportViolation: PropTypes.bool,
  toggledReportViolationId: PropTypes.number,
  deleteViolationStatus: PropTypes.string,
  reportViolationError: PropTypes.object,
  deleteViolationError: PropTypes.object,
  siteDetailData: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    pre_encode_image: PropTypes.string,
    site_id: PropTypes.number
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(EachViolationDetailPage)
